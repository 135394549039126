import Alert from '@mui/material/Alert';

export const NoCrestIdAlert = () => {
    return (
        <Alert severity="error" className="Alert">
            <l>The details you have entered does not have the necessary information associated with it.</l>
            <l>To create a CRESTDrive account, please ensure:</l>
            <ol type="1">
                <li>You have booked a CREST practical exam with Pearson VUE and received a booking confirmation</li>
                <li>You are using the same e-mail address to create an account that was used when booking the exam</li>
            </ol>
            <l>If you need further assistance, feel free to contact our exams support team at <a href="examsupport@crest-approved.org" target="_blank" rel="noreferrer">examsupport@crest-approved.org</a>.</l>
        </Alert>
    )
}
