export function formatBytes (bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'
    
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['B', 'KB', 'MB', 'GB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export function formatBytesToMB (bytes) {
    return (bytes / (1024*1024));
}

export function formatMBToBytes (megabytes) {
    return (megabytes * (1024*1024));
}