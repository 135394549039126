import React, { Fragment, useContext, useState } from "react";
import Button from 'react-bootstrap/Button';
import {
    NavigateBefore as NavigateBeforeIcon,
    NavigateNext as NavigateNextIcon
} from '@mui/icons-material';
import { Box, Card, DialogContent, DialogActions, IconButton } from '@mui/material';
import { formatBytes } from "../utils/fileUtils";
import { FormattedDialog } from "./Dialog/FormattedDialog";
import { FormattedDialogTitle } from "./Dialog/FormattedDialogTitle";
import ExamContext from "../context/ExamContext";
import ModalContext from "../context/ModalContext";
import { UploadDuplicateDialog } from "./UploadDuplicateDialog";
import { formatBytesToMB } from "../utils/fileUtils";

import Alert from '@mui/material/Alert';
import {
    Close as CloseIcon
} from '@mui/icons-material';
import '../styles/App.css';

export const UploadModal = () => {
    const { remainingSpace, examLoaded, notesLoaded } = useContext(ExamContext)
    const { setTermsOpen } = useContext(ModalContext)

    const [selectedFiles, setSelectedFiles] = useState(null)
    const [currentCard, setCurrentCard] = useState(0)
    const [remainingSpaceAlert, setRemainingSpaceAlert] = useState(false)
    const [uploadOpen, setUploadOpen] = useState(false)
    const [disableUpload, setDisableUpload] = useState(true)

    const handleOpen = () => {
        setSelectedFiles(null)
        setDisableUpload(true)
        setUploadOpen(true)
    };
    
    const handleClose = () => {
        setSelectedFiles(null)
        setDisableUpload(true)
        setUploadOpen(false)
    };

    const handleCloseAlert = () => {
        setRemainingSpaceAlert(false)
    }

    const onFileClick = (event) => {
        event.target.value = ''
    }

    const onFileChange = (event) => {
        setSelectedFiles(event.target.files)
        const newFiles = Array.from(event.target.files)
        const totalSizeBytes = newFiles.reduce((total, file) => file.size + total,0)
        const totalSize = formatBytesToMB(totalSizeBytes)
        if (totalSize < remainingSpace) {
            console.log('Setting selected files', event.target.files)
            // setSelectedFiles(event.target.files)
            setDisableUpload(false)
        } else {
            console.log('Selected files are too large')
            setDisableUpload(true)
            setRemainingSpaceAlert(true)
            setSelectedFiles(null)
        }
    };

    const handlePreviousCard = () => {
        setCurrentCard(currentCard > 0 ? currentCard - 1 : Array.from(selectedFiles).length -1)
    }

    const handleNextCard = () => {
        setCurrentCard(currentCard < Array.from(selectedFiles).length - 1 ? currentCard + 1 : 0)
    }

    const TermsMessage = () => {
        return (
            <Box>
                <p>By accessing CRESTDrive, you accept the <a href="#!" onClick={(event) => setTermsOpen(true)} >Terms and Conditions</a> for Use of the File Uploader facility and agree to comply with its provisions.</p>
            </Box>
        )
    }

    const getDate = (file) => {
        if (file.lastModifiedDate) {
            return new Date(file.lastModifiedDate).toDateString()
        } else if (file.lastModified) {
            return new Date(file.lastModified).toDateString()
        } else {
            return null
        }
    }

    const FileData = () => {
        if (selectedFiles) {
            return (
                <DialogContent className="Contents">
                    { Array.from(selectedFiles).map((file, index) => {
                        return <Card key={"card-"+index} sx={{ boxShadow: 0, display: currentCard === index ? "block" : "none"}}>
                            <Box key={"box-"+index} className="Card">
                                <Box key={"innerbox-"+index}>
                                    <IconButton key={"beforeIconButton-"+index} className="Previous" onClick={handlePreviousCard}>
                                        <NavigateBeforeIcon  key={"beforeIcon-"+index} />
                                    </IconButton>
                                    <IconButton  key={"nextIconButton-"+index} className="Next" onClick={handleNextCard}>
                                        <NavigateNextIcon  key={"nextIcon-"+index} />
                                    </IconButton>
                                </Box>
                                <p  key={"filecount-"+index} className="Index"> {index + 1}/{Array.from(selectedFiles).length}</p>
                            </Box>
                            <h2 key={"fileDetails-"+index} >File Details:</h2>
                            <p key={"fileName-"+index} >
                                File Name:{" "}
                                {file.name}
                            </p>
                            <p key={"fileType-"+index} >
                                File Type:{" "}
                                {file.type}
                            </p>
                            <p key={"fileSize-"+index} >
                                Size:{" "}
                                {formatBytes(file.size)}
                            </p>
                            <p key={"lastModified-"+index} >
                                Last Modified:{" "}
                                {getDate(file)}
                            </p>
                        </Card>
                    })

                    }
                </DialogContent>
            );
        } else {
            return (
                <DialogContent className="Contents">
                    <Box>
                        <h4 className="Text">
                            Please select the file you would like to upload.
                        </h4>
                    </Box>
                    <TermsMessage />
                </DialogContent>
            );
        }
    };

    const Actions = (() => {
        return (
            <DialogActions className="Actions">
                <input
                    className="File-button"
                    type="file"
                    onClick={onFileClick}
                    onChange={onFileChange}
                    multiple
                />
                <UploadDuplicateDialog disabled={disableUpload} selectedFiles={selectedFiles} setUploadOpen={setUploadOpen}/>
            </DialogActions>
        )
    })

    return (
        <Fragment>
            <Button className="Upload font-medium" type="button" onClick={handleOpen} disabled={!(examLoaded && notesLoaded)}>Upload</Button>
            <FormattedDialog
                className="Modal"
                onClose={handleClose}
                open={uploadOpen}
            >
                {remainingSpaceAlert && <Alert severity="error" className="Alert" sx={{zIndex: 99999}}>
                    The selected files exceed the remaining space.
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseAlert}
                    >
                        <CloseIcon />
                    </IconButton>
                </Alert>}
                <FormattedDialogTitle title={'Upload'} handleClose={handleClose}/>
                <FileData />
                <Actions/>
            </FormattedDialog>
        </Fragment>
    )
}

