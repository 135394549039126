import React, { useContext, useEffect, useMemo, useState } from "react";
import ViewHeader from '../components/ViewHeader';
import { UploadModal } from "../components/UploadModal";
import { UploadRowActions } from "../components/UploadRowActions";
import Details from "../components/ViewPage/Details"
import * as S3 from '../lib/S3';
import { useParams, useNavigate } from "react-router-dom";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { notesTableConfig } from "../constants";
import { GetRemainingSpace } from "../utils/noteUtils";
import { ProgressModal } from "../components/ProgressModal";
import ExamContext from "../context/ExamContext";
import ExamsContext from "../context/ExamsContext";
import ProgressContext from "../context/ProgressContext";
import UserContext from "../context/UserContext";

import '../styles/App.css';
import { ViewPageTableColumns } from "../components/ViewPage/ViewPageTableColumns";

export default function ViewPage({cookies}) {
  const { exams } = useContext(ExamsContext)
  const { crestId, givenName, familyName } = useContext(UserContext)
  const navigate = useNavigate();
  const [examLoaded, setExamLoaded] = useState(false);
  const [notesLoaded, setNotesLoaded] = useState(false)
  const { id } = useParams();

  const setInitialExam = () => {
    if (id && exams && exams.length > 0) {
      return exams.find(({ registrationId }) => registrationId === parseInt(id))
    } else {
      return {
        "bookingId": "",
        "registrationId": 0,
        "examSeriesName": "",
        "examSeriesCode": "",
        "examDateTime": "",
        "testCenterId": 0
      }
    }
  }

  const [exam, setExam] = useState(setInitialExam())

  const [progressOpen, setProgressOpen] = useState(false)
  const [percent, setPercent] = useState(0)
  const [type, setType] = useState('')
  const [currentFileNumber, setCurrentFileNumber] = useState(0)
  const [fileTotal, setFileTotal] = useState(0)
  const [progressRows, setProgressRows] = useState([])
  const [progress, setProgress] = useState({
    open: false,
    percent: 0,
    type: '',
    currentFileNumber: 0,
    fileTotal: 0,
    rows: []
  })
  
  const [notes, setNotes] = useState([]);
  const [remainingSpace, setRemainingSpace] = useState(0)

  useEffect(() => {
    const getExam = async () => {
      if (exams && id && !examLoaded) {
        try {
          if (exams && exams.length > 0) {
            const examData = exams.find(({ registrationId }) => registrationId === parseInt(id));
            setExam(examData)
            setExamLoaded(true)
          }
        } catch (err) {
          console.log('fetchRegistrations error', err)
          navigate("/")
        }
      }
    }

    getExam()
  }, [exams, examLoaded, exam, id, crestId])
  
  useEffect(() => {
    const getNotes = async () => {
      if (id && !notesLoaded) {
        try {
          const uploaded = await S3.getUploaded(id);
          setNotes(uploaded)
          setNotesLoaded(true)
        } catch (err) {
          console.log('getUploaded error', err)
          navigate("/")
          // set max retries?
        }
      }
    }

    getNotes();
  }, [notesLoaded, notes, id, crestId])

  useEffect(() => {
    const getNotes = async () => {
      if (id && !notesLoaded) {
        try {
          const uploaded = await S3.getUploaded(id);
          setNotes(uploaded)
          setNotesLoaded(true)
        } catch (err) {
          console.log('getUploaded error', err)
          navigate("/")
          // set max retries?
        }
      }
    }

    if (!cookies.token || !crestId) {
      navigate("/")
    }

    getNotes()
  }, [])

  useEffect(() => {
    const newRemainingSpace = GetRemainingSpace(notes)
    setRemainingSpace(newRemainingSpace)
  }, [notes])

  useEffect(() => {
      if (exam.examSeriesName) {
          setExamLoaded(true)
      }
  }, [exam])

  const columns = useMemo(
      () => ViewPageTableColumns(),
      [],
  );

  const table = useMaterialReactTable({
      columns,
      data: notes,
      ...notesTableConfig,
      state: {
        isLoading: !notesLoaded
      },
      renderToolbarInternalActions: ({ table }) => (
          <UploadRowActions id={exam.registrationId} rows={table.getSelectedRowModel().rows} setProgressOpen={setProgressOpen}/>
      ),
      muiTableContainerProps: { 
        sx: { 
          overflow: 'visible',
          maxHeight: '100%'
        }
      }
  });

  return (
    <ExamContext.Provider
      value={{
        exam,
        setExam,
        remainingSpace,
        setRemainingSpace,
        notes,
        setNotes,
        examLoaded,
        setExamLoaded,
        notesLoaded,
        setNotesLoaded
      }}
    >
      <ProgressContext.Provider
        value={{
          percent,
          setPercent,
          progressOpen,
          setProgressOpen,
          type,
          setType,
          currentFileNumber,
          setCurrentFileNumber,
          fileTotal,
          setFileTotal,
          progressRows,
          setProgressRows,
          progress,
          setProgress
        }}
      >
        <div className="Page ViewPage">
            <ViewHeader id={id} examName={exam.examSeriesName} fullName={givenName + " " + familyName} examLoaded={examLoaded}/>
            {progressOpen && <ProgressModal />}
            <UploadModal id={id} />
            <Details examData={exam} remainingSpace={remainingSpace} />
            <div className="Table" >
              <MaterialReactTable table={table} />
            </div>
        </div>
      </ProgressContext.Provider>
    </ExamContext.Provider>
  );
}
