import { Box, IconButton, Tooltip } from '@mui/material';
import {
    Download as DownloadIcon,
} from '@mui/icons-material';
import { DeleteConfirmationDialog } from './DeleteConfirmationDialog';
import { Download } from '../utils/downloadUtils';
import { useContext } from 'react';
import ExamContext from '../context/ExamContext'
import NoteContext from '../context/NoteContext';
import ProgressContext from '../context/ProgressContext'
  
export const UploadRowActions = ({id, rows}) => {
    const examContext = useContext(ExamContext)
    const progressContext = useContext(ProgressContext)
    const noteContext = useContext(NoteContext)

    const setContext = async () => {
        progressContext.setPercent(0)
        progressContext.setCurrentFileNumber(0)
        progressContext.setProgressRows(rows)
        progressContext.setFileTotal(rows.length)
        progressContext.setType('Download')
        // progressContext.setProgress({
        //     open: true,
        //     percent: 0,
        //     type: 'Download',
        //     currentFileNumber: 0,
        //     fileTotal: 0,
        //     rows: rows
        //   })
    }

    const DownloadProgress = (rows) => {
        if (rows && rows.length > 0) {
            progressContext.setProgressOpen(true)
            setContext().then(() => Download(rows, examContext, noteContext, progressContext))
            // Download(rows, examContext, noteContext, progressContext)
        }
    }
      
    return (
        <Box>
            <Tooltip title="Download">
                <IconButton onClick={() => DownloadProgress(rows)}>
                    <DownloadIcon />
                </IconButton>
            </Tooltip>
            <DeleteConfirmationDialog rows={rows} />
        </Box>
    )
}