import React, { Fragment, useContext } from "react";
import { DialogContent, LinearProgress } from '@mui/material';
import { FormattedDialog } from "./Dialog/FormattedDialog"
import { FormattedDialogTitle } from "./Dialog/FormattedDialogTitle"
import ProgressContext from "../context/ProgressContext";

import '../styles/App.css';

export const ProgressModal = () => {
    const progress = useContext(ProgressContext)

    const handleOpen = () => {
        progress.setProgressOpen(true)
    }

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            progress.setProgressOpen(false)
        }
    };

    const Contents = () => {
        return (
            <DialogContent className="Contents">
                {progress.type === 'Upload' && <p className="font-light">
                    Your files are currently being uploaded, please do not close the tab.
                </p>}
                {progress.type === 'Download' && <p className="font-light">
                    Your files are preparing to download.
                </p>}
                {progress.type === 'Delete' && <p className="font-light">
                    Your files are currently being deleted, please do not close the tab.
                </p>}
                <p>{progress.currentFileNumber}/{progress.progressRows.length}</p>
                {typeof progress.percent === 'number' && <LinearProgress variant="determinate" value={progress.percent } />}
            </DialogContent>
        )
    }

    return (
        <Fragment>
            <FormattedDialog
                className="Modal ProgressModal"
                onClose={handleClose}
                open={progress.progressOpen}
            >
                <FormattedDialogTitle title={progress.type} handleClose={handleClose} noClose={true} />
                <Contents />
            </FormattedDialog>
        </Fragment>
    )
}
