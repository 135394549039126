//File for storing local mock functions

import { exams } from '../data/exams';
import { uploaded } from '../data/uploaded';

let examsMock = exams;
let uploadedMock = uploaded;

export function getExams() {
    return examsMock;
    ;
}

export function getUploaded() {
    return uploadedMock;
}

export function uploadFile() {
    uploadedMock.push()
}