import React, {Fragment} from "react";
import Button from 'react-bootstrap/Button';
import { AuthAPI } from "../apis/AuthAPI"

export const UpdateProfileButton = ({type='button'}) => {

  const UpdateProfile = async () => {
    try {
        const update_url = await AuthAPI.getUpdateUrl()
        window.location.href = update_url
    } catch (error) {
        console.error(error);
    }
  }

  return (
    <Fragment>
      {type === 'button' && <Button className="Header-updateprofile font-light" type="button" onClick={() => UpdateProfile()} data-testid="UpdateProfileButton">Update Profile</Button>}
      {type === 'menu' && <a className="menu-item font-light" href="!#" onClick={() => UpdateProfile()} data-testid="UpdateProfileMenu">Update Profile</a>}
    </Fragment>
  );
};
