import _ from 'lodash';
import * as local from '../lib/local';
import { D365API } from "../apis/D365API"

const env = process.env.APP_ENV || process.env.NODE_ENV

export async function getExams (crestId) {
    if (env === 'production') {
        let exams = await D365API.fetchRegistrations(crestId)

        if (exams instanceof Object) {
            _.orderBy(exams, ['examDateTime'], ['asc'])
            return exams
        } else {
            throw new Error("Failed to retrieve the exams")
        }
        
    } else {
        const exams = local.getExams();
        return _.orderBy(exams, ['examDateTime'], ['asc']);
    }
}