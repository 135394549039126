//File for storing functions for handling S3
import * as local from '../lib/local';
import { FileAPI } from "../apis/FileAPI";
// import { formatBytes } from "../utils/fileUtils";

export async function getUploadedLocal() {
    const uploaded = [
        {"name": "2022_Logo_colour (1).ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
        {"name": "2022_Logo_colour.ico", "lastModifiedDate": "04/25/2024, 18:10:03", "size": 183128},
    ]

    return uploaded;
}

export async function getUploaded(registration_id) {
    const env = process.env.APP_ENV || process.env.NODE_ENV
    if (env === 'production') {
        const uploadedFileList = await FileAPI.list(registration_id)
        if (uploadedFileList instanceof Object) {
            return Array.from(uploadedFileList)
        } else {
            throw new Error("Failed to retrieve the uploaded notes")
        }
    } else {
        return await getUploadedLocal()
    }
}