import { Box, Card, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import '../styles/App.css';

export const TermsAndConditions = (maxHeight = 100) => {

    const gridContainer = {
        // display: "grid",
        // gridTemplateColumns: "repeat(2)"
      };

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        // padding: theme.spacing(1),
        textAlign: 'left',
        boxShadow: 0,
        color: theme.palette.text.secondary,
    }));

    const Title = () => {
        return (
            <Box>
                <h4 sx={{textAlign: 'center'}} className="TermsTitle">
                    CREST (INTERNATIONAL)
                </h4>
                <h5 className="TermsSubtitle">
                Terms and Conditions for use of File Uploader
                    Facility for Pearson Vue Examinations
                </h5>
            </Box>
        )
    }

    const Definitions = () => {
        return (
            <Card sx={{boxShadow: 0}}>
                <h5>
                    <b>1.</b>  DEFINITIONS
                </h5>
                <Grid container sx={{boxShadow: 0, gridContainer}}>
                    <Grid item xs={4} sx={{boxShadow: 0}}>
                        <Item sx={{boxShadow: 0}}>“Archived”</Item>
                    </Grid>
                    <Grid item xs={8}>
                        <Item sx={{boxShadow: 0}}>means held securely in CREST’s AWS account and with restricted access.</Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item sx={{boxShadow: 0}}>“CREST”</Item>
                    </Grid>
                    <Grid item xs={8}>
                        <Item sx={{boxShadow: 0}}>means CREST (International), with Company Registration number 09805375 and any or all of its group of companies.</Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item sx={{boxShadow: 0}}>“Exam Candidate”</Item>
                    </Grid>
                    <Grid item xs={8}>
                        <Item sx={{boxShadow: 0}}>means an individual that is booked to take a CREST examination at a Pearson Vue centre.</Item>
                    </Grid>
                </Grid>
            </Card>
        )
    }

    const TheService = () => {
        return (
            <Box>
                <h5>
                    <b>2.</b>  THE SERVICE
                </h5>
                <div>
                    <Typography>
                        <b>1.</b>  CREST will provide Exam Candidates with a facility to upload files designed solely to support them during their exam.  For the avoidance of doubt, files that provide unfair advantage to candidates such as providing answers will be deemed a breach of CREST’s non-disclosure agreement and its Code of Conduct.
                    </Typography>
                    <Typography>
                        <b>2.</b>  CREST reserves the right in its sole discretion to review the content of an Exam Candidate’s Files where it suspects any fraudulent or dishonest behaviour has occurred.
                    </Typography>
                    <Typography>
                        <b>3.</b>  The upload facility is not a place to store files. It is only a place to upload copies of files to aid an Exam Candidate during an exam.
                    </Typography>
                </div>
            </Box>
        )
    }

    const ConditionsOfUse = () => {
        return (
            <Box>
                <h5>
                    <b>3.</b>  CONDITIONS OF USE
                </h5>
                <div>
                    <Typography>
                        <b>1.</b> Files will be uploaded to the location named “CRESTDrive”. The upload limit is a total of 100MB per exam.
                    </Typography>
                    <Typography>
                        <b>2.</b>  Exam Candidates must not extract any exam content. Such action will be deemed a breach of the Non-Disclosure Agreement.
                    </Typography>
                    <Typography>
                        <b>3.</b>  Exam Candidates will be able to access CRESTDrive more than once in advance of their exam to upload files. Candidates should note, however, that all uploads must have been completed 24 hours before the date/time of their exam (See Clause 6).
                    </Typography>
                    <Typography>
                        <b>4.</b>  Exam Candidates will be able to delete, view and download content but must be aware that the facility is not a document management solution.
                    </Typography>
                    <Typography>
                        <b>5.</b>  Exam Candidates will not have access to their uploaded files after taking an exam.
                    </Typography>
                    <Typography>
                        <b>6.</b>  After the exam, files uploaded will be Archived and Exam Candidates will no longer have access to them.
                    </Typography>
                    <Typography>
                        <b>7.</b>  Exam Candidates’ files will be Archived for three months and then securely deleted.
                    </Typography>
                    
                </div>
            </Box>
        )
    }

    const Encryption = () => {
        return (
            <Box>
                <h5>
                    <b>4.</b> ENCRYPTION
                </h5>
                <div>
                    <Typography>
                        <b>1.</b>  If files have been encrypted for upload, the Exam Candidate will be required to unencrypt them should CREST require access for any reason.
                    </Typography>
                    <Typography>
                        <b>2.</b>  If an Exam Candidate is unwilling or unable to unencrypt their files, CREST may invoke sanctions as described in the Code of Conduct for Exam Candidates. <a href="https://www.crest-approved.org/about-us/governance/" target="_blank" rel="noreferrer">(Governance - CREST (crest-approved.org))</a>
                    </Typography>
                </div>
            </Box>
        )
    }

    const ExamDeferment = () => {
        return (
            <Box>
                <h5>
                    <b>5.</b>  EXAM DEFERMENT
                </h5>
                <div>
                    <Typography>
                        <b>1.</b> If a CREST exam is rescheduled, files uploaded to CRESTDrive will be retained and Exam Candidates will be able to access them in line with Clause 3.3.
                    </Typography>
                    <Typography>
                        <b>2.</b>  If an exam is cancelled, files uploaded to CRESTDrive will be deleted.
                    </Typography>
                </div>
            </Box>
        )
    }

    const General = () => {
        return (
            <Box>
                <h5>
                    <b>6.</b> General
                </h5>
                <div>
                    <Typography>
                    Exam Candidates will receive an email reminder to upload their files or an email summarising the files that they have uploaded but must have completed this 24 hours in advance of their exam date/time.  This will ensure that their files are available to them for their exam.
                    </Typography>
                    <Typography>
                        Further information is available on the CREST website - <a href="https://www.crest-approved.org/skills-certifications-careers/CRESTDrive" target="_blank" rel="noreferrer">https://www.crest-approved.org/skills-certifications-careers/CRESTDrive</a>
                    </Typography>
                </div>
            </Box>
        )
    }

    return (
        <Box maxHeight={maxHeight}>
            <Title />
            <Definitions />
            <TheService />
            <ConditionsOfUse />
            <Encryption />
            <ExamDeferment />
            <General />
        </Box>
    )
}