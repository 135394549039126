import { SetNewNote } from './updateNotesUtils';
import * as S3 from '../lib/S3';
import { sizeLimitMB } from "../constants";
import { formatBytesToMB } from "../utils/fileUtils";


export const UpdateNotesList = async (examContext, notesContext) => {
    const uploaded = await S3.getUploaded(examContext.exam.registrationId)
    examContext.setNotes(uploaded)
    SetNewNote(uploaded, examContext, notesContext)
}

export const GetRemainingSpace = (uploaded) => {
    if (uploaded && uploaded.length > 0) {
        const totalSize = uploaded.reduce((total, file) => file.size + total,0)
        const newRemainingSpace = (sizeLimitMB - formatBytesToMB(totalSize))
        return newRemainingSpace;
    } else {
        return sizeLimitMB;
    }
}
