import * as Constants from "../../constants.js";
import axios from "axios";

const errorHandler = (error) => {
    console.log('axios errorHandler', error)
    const statusCode = error.response?.status
  
    if (statusCode && statusCode !== 401) {
      console.error(error)
    }
  
    throw error
}

const api = axios.create({
  baseURL: Constants.baseUrl,
  // withCredentials: true,
});

// api.interceptors.response.use(
//   // (response) => {
//   //   if (response.status === 200) {
//   //     return response;
//   //   } else {  
//   //     const messages = response.data.messages
//   //     if (messages) {
//   //       if (messages instanceof Array) {
//   //         return Promise.reject({ messages });
//   //       }
//   //       return Promise.reject({ messages: [messages] });
//   //     }
//   //     return Promise.reject({ messages: ["got errors"] });
//   //   }
//   // },
//   (error) => {
//     //unauthorised error
//     if (error.response && error.response.status === 401) {
//       localStorage.removeItem("token");
//       history.replace({ pathname: "/error" });
//     } 
//    //internal server error
//    else if (error.response && error.response.status === 500) {
//       return Promise.reject(error.response);
//     } 
//    //any other error
//   else return Promise.reject(error);
//   }
// );

// axios.interceptors.response.use(response => {
//   return response.headers['content-type'] === 'application/json' ? response : Promise.reject(response);
// }, error => Promise.reject(error));


api.interceptors.response.use(undefined, (error) => {
    return errorHandler(error)
})

export default api;