import api from "./configs/AxiosConfig.js"
import Cookies from 'js-cookie'

const env = process.env.APP_ENV || process.env.NODE_ENV
const url = window.location.origin.toString();

const FetchRegistrationsAPI = url + "/v2/registrations/list"
const UpdateNotesAPI = url + "/v2/registrations/update"

export const D365API = {
    fetchRegistrations: async function (crestId) {
        if (env === 'production') {
            const token = Cookies.get('token')
            const jsonToken = JSON.parse(token)
            const idToken = jsonToken.id_token
            const response = await api.get(FetchRegistrationsAPI, {
                params: {
                    crest_id: crestId,
                    // date: Date.now()
                },
                headers: {
                    Authorization: `Bearer ${idToken}`
                }
            })
            return response.data
        } else {
            return "";
        }
    },
    updateNotes: function (newNote) {
        if (env === 'production') {
            try {
                const token = Cookies.get('token')
                const jsonToken = JSON.parse(token)
                const idToken = jsonToken.id_token
                return api.post(
                    UpdateNotesAPI,
                    JSON.stringify(newNote), 
                    {
                        params: {
                            registration_id: newNote.registrationId,
                        },
                        headers: {
                            Authorization: `Bearer ${idToken}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );
            } catch (err) {
                console.log('uploadNotes error:', err)
                return Promise.reject(err)
            }
            
        } else {
            return "";
        }
    }
}