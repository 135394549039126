import React, {Fragment} from "react";
import { Link } from "react-router-dom";
import logo from '../images/logo192.png';
import { applicationName } from "../constants";
import { HowToButton } from "./HowToButton";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import { UpdateProfileButton } from "./UpdateProfileButton";
import { ChangePasswordButton } from "./ChangePasswordButton";
import { ManageMFAButton } from "./ManageMFAButton";
import { DeleteAccountDialog } from "./DeleteAccountDialog";
import Media from 'react-media';
import { slide as Menu } from 'react-burger-menu'
import '../styles/App.css';

export default function PageHeader({cookies, removeCookie, updateNotes}) {
    return (
        <header className="Header font-medium">
            <Link to ='/' className="Header-logo-link">
                <img src={logo} className="Header-logo" alt="logo" width="50" height="60"/>
            </Link>
            <h1 className="Header-title font-header">
                {applicationName}
            </h1>
            <Media queries={{ 
                small: "(max-width: 899px)",
                large: "(min-width: 900px)"
            }}>
                {matches => (
                    <Fragment>
                        {/* {matches.large && <Fragment>
                            <ManageMFAButton />
                            <DeleteAccountButton />
                            <HowToButton />
                            <UpdateProfileButton />
                            <ChangePasswordButton />
                            {cookies.token && <SignOutButton removeCookie={removeCookie} updateNotes={updateNotes}/>}
                            {!cookies.token && <SignInButton />}
                        </Fragment>}
                        {matches.small && <Fragment> */}
                            <Fragment>
                                <Menu right width={ matches.large ? '20%' : '100%' } noOverlay>
                                    <HowToButton type='menu' />
                                    <br />
                                    <UpdateProfileButton type='menu' />
                                    <br />
                                    <ChangePasswordButton type='menu' />
                                    <br />
                                    <ManageMFAButton type='menu' />
                                    <br />
                                    <DeleteAccountDialog type='menu' />
                                    <br />
                                    {cookies.token && <SignOutButton type='menu' removeCookie={removeCookie} />}
                                    {!cookies.token && <SignInButton type='menu' />}
                                </Menu>
                            </Fragment>
                        {/* } */}
                    </Fragment>
                )}
            </Media>
        </header>
    );
}
