import { createSlice } from '@reduxjs/toolkit'

export const progressSlice = createSlice({
    name: 'progress',
    initialState: {
        inProgress: false,
        type: "",
        progressPercent: 0,
        currentFileNumber: 0,
        fileTotal: 0
    },
    reducers: {
        setInProgress: (state, action) => {
          state.inProgress = action.payload;
        },
        setType: (state, action) => {
          state.type = action.payload;
        },
        setProgressPercent: (state, action) => {
          state.progressPercent = action.payload;
        },
        setCurrentFileNumber: (state, action) => {
          state.currentFileNumber = action.payload;
        },
        setFileTotal: (state, action) => {
          state.fileTotal = action.payload;
        },
        reset: (state, action) => {
          state.inProgress = false;
          state.type = "";
          state.progressPercent = 0;
          state.currentFileNumber = 0;
          state.fileTotal = 0;
        },
        start: (state, action) => {
          state.inProgress = true;
          state.type = action.payload.type
          state.progressPercent = 0;
          state.currentFileNumber = 0;
          state.fileTotal = action.payload.fileTotal;
        }
    }
})

export const {
    setInProgress,
    setType,
    setProgressPercent,
    setCurrentFileNumber,
    setFileTotal,
    reset,
    start
} = progressSlice.actions;

export default progressSlice.reducer;
  