import { formatDate } from "../../utils/dateUtils";

export const ExamPageTableColumns = () => {
    return [
        {
          accessorKey: 'examSeriesName',
          header: 'Exam Name',
          size: 150,
        },
        {
          accessorKey: 'registrationId',
          header: 'Registration ID',
          size: 150,
        },
        {
          accessorFn: (row) => formatDate(row.examDateTime),
          id: 'examDateTime',
          header: 'Datetime',
          filterVariant: 'date',
          filterFn: 'lessThan',
          sortingFn: 'datetime',
          Cell: ({ cell }) => cell.getValue()?.toString(),
          Header: ({ column }) => <em className='TableHeader'>{column.columnDef.header}</em>,
          muiFilterTextFieldProps: {
            sx: {
              minWidth: '250px',
            },
          },
        },
    ]
}