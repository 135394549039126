import React, {Fragment} from "react";
import Button from 'react-bootstrap/Button';
import { AuthAPI } from "../apis/AuthAPI"

export const ChangePasswordButton = ({type='button'}) => {

  const ChangePassword = async () => {
    try {
        const password_url = await AuthAPI.getPasswordUrl()
        window.location.href = password_url
    } catch (error) {
        console.error(error);
    }
  }

  return (
    <Fragment>
      {type === 'button' && <Button className="Header-changepassword font-light" type="button" onClick={() => ChangePassword()}>Change Password</Button>}
      {type === 'menu' && <a className="menu-item font-light" href="#!" onClick={() => ChangePassword()}>Change Password</a>}
    </Fragment>
  );
};
