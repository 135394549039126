import React, { Fragment, useContext } from "react";
import Button from 'react-bootstrap/Button';
import { AuthAPI } from "../apis/AuthAPI";
import { D365API } from "../apis/D365API";
import NoteContext from '../context/NoteContext'

export const SignOutButton = ({type='button', removeCookie}) => {
  
  const { updateNotes } = useContext(NoteContext)

  const Logout = async () => {
    try {

      let updatePromises = [];
      if (updateNotes && updateNotes.length > 0) {
        for (const note of updateNotes) {
          updatePromises.push(D365API.updateNotes(note));
        }
      }

      Promise.allSettled(updatePromises).then(() => {
        removeCookie('token', {
          path: "/"
        })
      }).then(() => {
        return AuthAPI.getSignout()
      }).then((signout_url) => {
        window.location.href = signout_url
      });
    } catch (error) {
        console.error(error);
    }
  }
  
  return (
    <Fragment>
      {type === 'button' && <Button className="Header-logout font-light" type="button" onClick={() => Logout()}>Logout</Button>}
      {type === 'menu' && <a className="menu-item font-light" href="#!" onClick={() => Logout()}>Logout</a>}
    </Fragment>
  );
};
