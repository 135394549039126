
export const clientId = "a51d5e02-0fe2-4604-b015-535002f9d889"
export const authority = "https://login.microsoftonline.com/common/"
// export const authority = "https://CRESTB2CDEV.b2clogin.com"
export const baseUrl = ""

export const applicationName = "CRESTDrive"

export const examTableConfig = {
    enableColumnActions: false,
    enableColumnFilters: false,
    enableDensityToggle: false,
    enableColumnResizing: false,
    enableFullScreenToggle: false,
    enableSortingRemoval: true,
    enableGlobalFilter: false,
    enableStickyHeader: true,
    // enableStickyFooter: true,
    enablePagination: false,
    enableBottomToolbar: false,
    initialState: { pagination: { pageSize: 5 } },
    muiCircularProgressProps: {
        color: 'blue',
        thickness: 5,
        size: 55,
    },
        muiSkeletonProps: {
        animation: 'pulse',
        height: 28,
    },
    mrtTheme: (theme) => ({
        baseBackgroundColor: theme.palette.background.default, //change default background color
    }),
}

export const notesTableConfig = {
    enableColumnActions: false,
    enableColumnFilters: false,
    enableDensityToggle: false,
    enableColumnResizing: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enableSortingRemoval: true,
    enableGlobalFilter: false,
    enableRowSelection: true, 
    enableBatchRowSelection: true,
    enableMultiRowSelection: true,
    enableSelectAll: true,
    selectAllMode: 'all',
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    muiCircularProgressProps: {
        color: 'blue',
        thickness: 5,
        size: 55,
    },
        muiSkeletonProps: {
        animation: 'pulse',
        height: 28,
    },
    mrtTheme: (theme) => ({
        baseBackgroundColor: theme.palette.background.default, //change default background color
    })
}

export const region = "eu-west-1"

export const loremIpsum = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce enim dolor, bibendum id pretium eget, mattis ut justo. In consequat tristique posuere. Nulla molestie felis felis. Maecenas ut enim malesuada, dictum dolor vitae, pulvinar elit. Aenean est purus, venenatis eget sem in, gravida ultricies nisl. Ut ultricies non dui a sodales. Sed in quam a diam fringilla vestibulum vel quis eros. Etiam rhoncus gravida viverra. Sed vitae sollicitudin neque, ut dignissim purus. Cras augue ligula, semper a tempor quis, pretium eu mauris. Nullam ultrices scelerisque vulputate. Nulla facilisi. Duis ac eleifend purus, at placerat libero. Duis id mollis ex, non elementum eros. Morbi eros augue, scelerisque eu metus quis, placerat consectetur velit. Etiam ac arcu ut turpis fermentum fringilla non ac lacus.\
\
Nunc nisi metus, porttitor consectetur massa nec, dapibus lacinia sapien. Integer dignissim nunc vitae tellus scelerisque, ac fermentum mauris finibus. Integer fermentum erat a eros blandit elementum at eu lacus. Nulla faucibus dignissim augue, ut ullamcorper nisl condimentum non. Vestibulum vitae justo elementum, tincidunt orci non, vulputate lacus. Nullam at nibh sed augue suscipit mattis. Mauris quis imperdiet libero, semper sodales ante. Aliquam bibendum enim eu ex convallis, nec venenatis odio egestas.\
\
Curabitur aliquam tristique dapibus. Donec efficitur suscipit aliquam. Duis facilisis dolor rhoncus varius sodales. Maecenas ut lectus metus. Nulla ligula mauris, vehicula vitae purus mollis, tempor sodales lacus. Etiam scelerisque laoreet magna at fermentum. Donec sit amet odio a dolor aliquam condimentum. Ut tempor cursus justo, tincidunt ullamcorper nunc dictum non. Morbi facilisis aliquam leo, sit amet sollicitudin lorem elementum in. Cras pretium ultrices est, at convallis ex lacinia non. Mauris a mi a quam suscipit dictum. Proin vestibulum dapibus urna eu venenatis. Fusce cursus cursus urna id congue. Quisque non semper elit, at elementum felis. Aliquam malesuada mauris a volutpat commodo.\
\
Sed quis placerat nisl, eget accumsan ipsum. Donec vestibulum ultrices velit, id hendrerit sapien tristique sit amet. Ut tempor libero non ex vulputate interdum et eget erat. Etiam vitae neque semper, gravida ante quis, convallis massa. Morbi venenatis nisl consectetur libero commodo interdum. Donec ac enim bibendum, ultrices lacus non, sodales eros. Praesent in malesuada velit. Curabitur dapibus sem in feugiat mattis. Nullam vestibulum sollicitudin elit at interdum. Morbi arcu turpis, bibendum id placerat a, lacinia ac ex. Donec gravida nec nulla eu semper. Aliquam erat volutpat. Sed nisi eros, posuere vitae gravida eget, semper sed arcu. Morbi non sapien quis ligula lobortis congue.\
\
Nunc maximus, nunc a cursus vestibulum, urna justo rhoncus leo, quis sodales augue purus sit amet risus. Donec varius mi sit amet mauris auctor efficitur. Nunc ante ante, aliquam ut odio quis, dapibus maximus elit. Integer a diam nibh. Nunc ac justo magna. Pellentesque tellus magna, posuere vel ornare non, aliquet vel elit. Nulla fringilla massa vel tincidunt gravida."

export const contactEmails = {
    // "IT": "ithelpdesk@crest-approved.org",
    // "Exams Support": "examsupport@crest-approved.org",
    // "Governance": "governance@crest-approved.org",
    "Tech Support": "support@crest-approved.org"
}

export const API = {
    List: process.env.REACT_APP_LIST_API,
    Download: process.env.REACT_APP_DOWNLOAD_API,
    Upload: process.env.REACT_APP_UPLOAD_API,
    Delete: process.env.REACT_APP_DELETE_API
}


export const SERVER_PORT = 3000
export const APP_CLIENT_ID = "a51d5e02-0fe2-4604-b015-535002f9d889"
export const SESSION_SECRET = "Lab8Q~~dS2mKWQRSBuZTxyeDpeVoSr7WCli2mce_"
export const APP_CLIENT_SECRET = "Lab8Q~~dS2mKWQRSBuZTxyeDpeVoSr7WCli2mce_"
export const POLICY_AUTHORITY = "https://CRESTB2CDEV.b2clogin.com/CRESTB2CDEV.onmicrosoft.com"
export const SIGN_UP_SIGN_IN_POLICY_AUTHORITY="https://CRESTB2CDEV.b2clogin.com/CRESTB2CDEV.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN"
export const RESET_PASSWORD_POLICY_AUTHORITY = "https://CRESTB2CDEV.b2clogin.com/CRESTB2CDEV.onmicrosoft.com/B2C_1A_CHANGEPASSWORD"
export const EDIT_PROFILE_POLICY_AUTHORITY = "https://CRESTB2CDEV.b2clogin.com/CRESTB2CDEV.onmicrosoft.com/B2C_1A_PROFILEEDIT"
export const AUTHORITY_DOMAIN = "https://CRESTB2CDEV.b2clogin.com"
export const APP_REDIRECT_URI = "https://dev.drive.crest-approved.org"
export const LOGOUT_ENDPOINT = "https://CRESTB2CDEV.b2clogin.com/CRESTB2CDEV.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN/oauth2/v2.0/logout?post_logout_redirect_uri=https://dev.drive.crest-approved.org"
export const SCOPES = "openid offline_access profile https://graph.microsoft.com/.default"

export const sizeLimitMB = 100