import { FileAPI } from "../apis/FileAPI"
import { UpdateNotesList } from "../utils/noteUtils"

const timer = (delay) => {
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export const DeleteRows = async (rows, examContext, noteContext, progressContext) => {
    if (rows && rows.length > 0) {
        const percentPerFile = 100 / rows.length
        for (const [index, row] of Object.entries(rows)) {
            const number = Number(index) + 1
            progressContext.setCurrentFileNumber(number)
            await FileAPI.delete(examContext.exam.registrationId, row.original.name)
            await timer(200)
            progressContext.setPercent(number * percentPerFile)
        }

        UpdateNotesList(examContext, noteContext)
    }
}
