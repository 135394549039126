export const uploaded = [
    {
        "Contents": [
            {
                "ETag": "\"70ee1738b6b21e2c8a43f3a5ab0eee71\"",
                "Key": "happyface.jpg",
                "LastModified": "2014-11-21T19:40:05.000Z",
                "Size": 1048576,
                "StorageClass": "STANDARD"
            },
            {
                "ETag": "\"becf17f89c30367a9a44495d62ed521a-1\"",
                "Key": "test.jpg",
                "LastModified": "2014-05-02T04:51:50.000Z",
                "Size": 4192256,
                "StorageClass": "STANDARD"
            }
        ],
        "IsTruncated": true,
        "KeyCount": "2",
        "MaxKeys": "2",
        "Name": "EXAMPLE_BUCKET_NAME",
        "NextContinuationToken": "1w41l63U0xa8q7smH50vCxyTQqdxo69O3EmK28Bi5PcROI4wI/EyIJg==",
        "Prefix": ""
    }
]