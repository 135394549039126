import React, { Component, Fragment, useContext } from "react";
import {
    Close as CloseIcon,
} from '@mui/icons-material';
import { Box, DialogTitle, DialogContent, IconButton } from '@mui/material';
import { TermsAndConditions } from "./TermsAndConditions";
import { FormattedDialog } from "./Dialog/FormattedDialog"
import { FormattedDialogTitle } from "./Dialog/FormattedDialogTitle"
import ModalContext from "../context/ModalContext"

import '../styles/App.css';

export const TermsModal = () => {
    const {
        termsOpen,
        setTermsOpen,
    } = useContext(ModalContext)

    const handleOpen = () => {
        setTermsOpen(true)
        
    };
    
    const handleClose = () => {
        setTermsOpen(false)
    };

    const Contents = () => {
        return (
            <DialogContent>
                <TermsAndConditions />
            </DialogContent>
        )
    }
    
    return (
        <Fragment>
            <p className="Footer-terms font-footer" onClick={handleOpen}>Terms & Conditions</p>
            <FormattedDialog
                className="Modal"
                onClose={handleClose}
                open={termsOpen}
                scroll='paper'
                fullWidth
                maxWidth="sm"
                aria-labelledby="Terms Popup"
                aria-describedby="Popup used for to show the T&Cs"
            >
                <FormattedDialogTitle title={'Terms & Conditions'} handleClose={handleClose}/>
                <Contents className="Content"/>
            </FormattedDialog>
        </Fragment>
    )
}

export default TermsModal;
