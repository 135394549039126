
import { sizeLimitMB } from "../../constants";
import { Box } from '@mui/material';
import '../../styles/App.css';
import { formatDate } from "../../utils/dateUtils";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Details({examData, remainingSpace}) {
    return (
        
        <Accordion defaultExpanded disableGutters style={{boxShadow: "none"}} className="DetailsAccordion" >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{flexDirection: "row-reverse"}}
                className="Summary"
                style={{boxShadow: "none"}} 
            >
            <p className="font-medium">Details</p>
            </AccordionSummary>
            <AccordionDetails 
                className='Details'
                sx={{margin: 0, padding: 0}}
            >
                <div className="TextDiv">
                    <p className="font-medium Text">
                        <strong className="font-medium Strong">Date and Time (Test Centre Time Zone): </strong> {examData.examDateTime ? formatDate(examData.examDateTime).toString() : ''}
                    </p>
                    <p className="font-medium Text">
                        <strong className="font-medium Strong">Pearson Vue Test Centre ID: </strong> {examData.testCenterId}
                    </p>
                    <p className="font-medium Text">
                        <strong className="font-medium Strong">Storage Remaining: </strong> {remainingSpace && typeof remainingSpace === 'number' ? remainingSpace.toFixed(2) : sizeLimitMB}MB
                    </p>
                </div>
            </AccordionDetails>
        </Accordion>
    )
}

