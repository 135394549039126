import React from "react";
import CloseButton from 'react-bootstrap/CloseButton';
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';

import '../styles/App.css';

export default function ViewHeader(props) {
    const navigate = useNavigate();

    return (
        <header className="Header font-medium">
            {/* {props.examLoaded && <div className="BreadcrumbDiv"> 
                <Breadcrumbs aria-label="breadcrumb" className="Breadcrumb">
                    <Link underline="hover" color="light-grey" className="BreadcrumbsLink" onClick={() => navigate("/")} >
                        <h2 className="BreadcrumbsLink font-header">{props.fullName}</h2>
                    </Link>
                    <h2 className="BreadcrumbsText font-header">{props.examName}</h2>
                </Breadcrumbs>
            </div>} */}
            {props.examLoaded && <h2 className="Text font-header">{props.id + " - " + props.examName}</h2>}
            {!props.examLoaded && <CircularProgress  className="Load" />}
            {/* <CloseButton className="Close font-light" onClick={() => navigate("/")}><ArrowBackIosNewIcon /></CloseButton> */}
            <CloseButton className="Close font-light" onClick={() => navigate("/")}>{String.fromCharCode(8592) + " Back to Exams"}</CloseButton>
        </header>
    );
}