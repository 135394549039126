import React, { Component, Fragment } from "react";
import { DialogContent } from '@mui/material';
import { FormattedDialog } from "./Dialog/FormattedDialog"
import { FormattedDialogTitle } from "./Dialog/FormattedDialogTitle"

import '../styles/App.css';

class ContactUsModal extends Component {

    handleOpen = () => {
        this.setState({
            open: true
        });
    };
    
    handleClose = () => {
        this.setState({
            open: false,
        });
    };
  
    state = {
        open: false
    };

    Contents = () => {
        return (
            <DialogContent>
                <p className="font-light">
                    If you need any assistance, please contact our Technical Support team at <a href={'mailto:support@crest-approved.org'}>support@crest-approved.org</a>.
                </p>
                <p className="font-light">
                    We are here to help with any issues or enquiries you may have.
                </p>
            </DialogContent>
        )
    }
    
    render() {
        return (
            <Fragment>
                <p className="Footer-contact font-footer" onClick={this.handleOpen}>Contact Us</p>
                <FormattedDialog
                    className="Modal"
                    onClose={this.handleClose}
                    open={this.state.open}
                >
                    <FormattedDialogTitle title={'Contact Us'} handleClose={this.handleClose}/>
                    <this.Contents className="Content"/>
                </FormattedDialog>
            </Fragment>
        )
    }
}

export default ContactUsModal;
