import React, { useContext, useMemo} from "react";
import { useNavigate } from "react-router-dom";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { examTableConfig } from "../constants";
import ExamsContext from '../context/ExamsContext'

import '../styles/App.css';
import { ExamPageTableColumns } from "../components/ExamPage/ExamPageTableColumns";

export default function ExamPage({setSearchParams}) {
  const { exams, examsLoaded } = useContext(ExamsContext)
  
  const navigate = useNavigate();

  const columns = useMemo(
      () => ExamPageTableColumns(),
      [],
  );
  
  const table = useMaterialReactTable({
    columns,
    data: exams,
    ...examTableConfig,
    muiTableBodyRowProps: ({ row }) => ({
        onClick: () => {
            setSearchParams({})
            navigate("/" + row.original.registrationId);
        },
        sx: {
            cursor: 'pointer'
        },
    }),
    state: {
      isLoading: !examsLoaded
    },
    muiTableContainerProps: { 
      sx: { 
        height: '72vh'
      } 
    },
  });

  
  return (
      <div className="Page ExamPage">
          <MaterialReactTable className="ExamPage-table" table={table} />
      </div>
  );
}
