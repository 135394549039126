import { formatDate } from "../../utils/dateUtils";
import { formatBytesToMB } from "../../utils/fileUtils";

const getDate = (row) => {
  if (row.lastModifiedDate) {
    return formatDate(row.lastModifiedDate)
  } else if (row.lastModified) {
    return formatDate(row.lastModified)
  } else {
    return null
  }
}

export const ViewPageTableColumns = () => {
    return [
        {
          accessorKey: 'name',
          header: 'File Name',
          size: 150,
        },
        {
          accessorFn: (row) => row.size,
          id: 'size',
          header: 'Size',
          Cell: ({ cell }) => formatBytesToMB(cell.getValue()).toFixed(2) + 'MB',
          Header: ({ column }) => <em className='TableHeader'>{column.columnDef.header}</em>,
          muiFilterTextFieldProps: {
            sx: {
              minWidth: '250px',
            },
          },
        },
        {
          accessorFn: (row) => getDate(row),
          id: 'lastModifiedDate',
          header: 'Uploaded Date',
          filterVariant: 'date',
          filterFn: 'lessThan',
          sortingFn: 'datetime',
          Cell: ({ cell }) => cell.getValue()?.toString(),
          Header: ({ column }) => <em className='TableHeader'>{column.columnDef.header}</em>,
          muiFilterTextFieldProps: {
            sx: {
              minWidth: '250px',
            },
          },
        },
    ]
}