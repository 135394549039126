import {
    Close as CloseIcon
} from '@mui/icons-material';
import { DialogTitle, IconButton, Typography } from '@mui/material';

export const FormattedDialogTitle = (props) => {
    return (
        <DialogTitle className="DialogTitle">
            <Typography className="Title font-header">{props.title}</Typography>
            {!props.noClose && <IconButton
                aria-label="close"
                onClick={props.handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>}
      </DialogTitle>
    )
}