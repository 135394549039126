import api from "./configs/AxiosConfig.js"
import {v4 as uuidv4} from 'uuid';

const env = process.env.APP_ENV || process.env.NODE_ENV
const url = window.location.origin.toString();

const GetCodeAPI = url + "/v2/auth/code"
const GetTokenAPI = url + "/v2/auth/token"
const GetSignoutAPI = url + "/v2/auth/signout"
const GetUpdateURLAPI = url + "/v2/auth/updateProfile"
const GetPasswordURLAPI = url + "/v2/auth/changePassword"
const GetManageMFAAPI = url + "/v2/auth/MFA"
const GetDeleteAccountAPI = url + "/v2/auth/deleteAccount"

export const AuthAPI = {
    getCode: async function () {
        if (env === 'production') {
            window.sessionStorage.setItem('nonce', uuidv4());

            const response = await api.get(GetCodeAPI, {
                params: {
                    nonce: window.sessionStorage.getItem('nonce')
                }
            })
            return response.data
        } else {
            return "https://crestb2cdev.b2clogin.com/crestb2cdev.onmicrosoft.com/b2c_1a_signup_signin/oauth2/v2.0/authorize?client_id=a51d5e02-0fe2-4604-b015-535002f9d889&response_type=code&redirect_uri=https%3A%2F%2Flocalhost:3000/redirect%2F&scope=https%3A%2F%2Fgraph.microsoft.com%2F.default+offline_access+openid+profile&state=login";
        }
    },
    getToken: async function (code) {
        if (env === 'production') {
            const nonce = window.sessionStorage.getItem('nonce')

            if (!nonce) {
                throw new Error("No nonce provided")
            }

            const response = await api.get(GetTokenAPI, {
                params: {
                    authorization_code: code,
                    nonce: nonce
                }
            })
            return response.data

            
        } else {
            return "";
        }
    },
    getSignout: async function () {
        if (env === 'production') {
            const response = await api.get(GetSignoutAPI)
            return response.data
        } else {
            return "https://CRESTB2CDEV.b2clogin.com/CRESTB2CDEV.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN/oauth2/v2.0/logout?post_logout_redirect_uri=localhost:3000";
        }
    },
    getUpdateUrl: async function () {
        if (env === 'production') {
            const response = await api.get(GetUpdateURLAPI)
            return response.data
        } else {
            return "https://crestb2cdev.b2clogin.com/crestb2cdev.onmicrosoft.com/B2C_1A_PROFILEEDIT/oauth2/v2.0/authorize?client_id=a51d5e02-0fe2-4604-b015-535002f9d889&response_type=code&redirect_uri=localhost:3000/redirect%2F&scope=https%3A%2F%2Fgraph.microsoft.com%2F.default+offline_access+openid+profile&state=edit";
        }
    },
    getPasswordUrl: async function () {
        if (env === 'production') {
            const response = await api.get(GetPasswordURLAPI)
            return response.data
        } else {
            return "https://crestb2cdev.b2clogin.com/crestb2cdev.onmicrosoft.com/b2c_1a_changepassword/oauth2/v2.0/authorize?client_id=6912e0f7-e6c2-452e-8e58-66a85355c049&response_type=code&redirect_uri=localhost:3000%2F&scope=https%3A%2F%2Fgraph.microsoft.com%2F.default+offline_access+openid+profile&state=edit";
        }
    },
    getManageMFA: async function () {
        if (env === 'production') {
            const response = await api.get(GetManageMFAAPI)
            return response.data
        } else {
            return "https://crestb2cdev.b2clogin.com/crestb2cdev.onmicrosoft.com/B2C_1A_MANAGEMFA/oauth2/v2.0/authorize?client_id=6912e0f7-e6c2-452e-8e58-66a85355c049&response_type=code&redirect_uri=localhost:3000%2F&scope=https%3A%2F%2Fgraph.microsoft.com%2F.default+offline_access+openid+profile&state=edit";
        }
    },
    getDeleteAccount: async function () {
        if (env === 'production') {
            const response = await api.get(GetDeleteAccountAPI)
            return response.data
        } else {
            return "https://crestb2cdev.b2clogin.com/crestb2cdev.onmicrosoft.com/B2C_1A_DELETEACCOUNT/oauth2/v2.0/authorize?client_id=6912e0f7-e6c2-452e-8e58-66a85355c049&response_type=code&redirect_uri=localhost:3000%2F&scope=https%3A%2F%2Fgraph.microsoft.com%2F.default+offline_access+openid+profile&state=edit";
        }
    }
}