import React, {Fragment} from "react";
import Button from 'react-bootstrap/Button';
import { AuthAPI } from "../apis/AuthAPI"

export const SignInButton = ({type='button'}) => {

  const Login = async () => {
    try {
        const auth_url = await AuthAPI.getCode()
        window.location.href = auth_url
    } catch (error) {
        console.error(error);
    }
  }
  
  return (
    <Fragment>
      {type === 'button' && <Button className="Header-logout font-light" type="button" onClick={() => Login()}>Login</Button>}
      {type === 'menu' && <a className="menu-item font-light" href="#!" onClick={() => Login()}>Login</a>}
    </Fragment>
  );
};
