import { Dialog } from '@mui/material';

export const FormattedDialog = (props) => {
  return (
    <Dialog
      PaperProps={{
        sx: {
          width: "50%",
        }
      }}
      {...props}
    >
    </Dialog>
  )
}