import { useContext } from 'react';
import ExamContext from '../context/ExamContext';
import { formatBytesToMB } from "./fileUtils";
import { sizeLimitMB } from "../constants";

const sizeMessage = "MB of " + sizeLimitMB + "MB Remaining"

function SetNewRemainingSpace(newNote, examContext) {
    const totalSize = newNote.reduce((total, file) => file.size + total,0)
    const newRemainingSpace = (sizeLimitMB - formatBytesToMB(totalSize))
    examContext.setRemainingSpace(newRemainingSpace)
    return newRemainingSpace
}

function GenerateNewChange(uploaded, examContext) {
    const newRemainingSpace = SetNewRemainingSpace(uploaded, examContext)
    const newStorageMessage = newRemainingSpace.toFixed(2) + sizeMessage

    const newChanges = {
        'registrationId': Number(examContext.exam.registrationId),
        'files': uploaded.length,
        'file_storage': newStorageMessage
    }

    return newChanges
}

function AddNewNote(newChanges, examContext, noteContext) {
    const existingNoteIndex = noteContext.updateNotes.findIndex(note => note.registrationId === examContext.exam.registrationId);
    if (existingNoteIndex >= 0) {
        const notesCopy = [...noteContext.updateNotes];
        notesCopy[existingNoteIndex] = newChanges;
        noteContext.setUpdateNotes(notesCopy);
    } else {
        noteContext.setUpdateNotes([...noteContext.updateNotes, newChanges])
    }
}

export function SetNewNote(uploaded, examContext, noteContext) {
    const newChanges = GenerateNewChange(uploaded, examContext)
    AddNewNote(newChanges, examContext, noteContext)
}
