import React, {Fragment} from "react";
import Button from 'react-bootstrap/Button';
import { AuthAPI } from "../apis/AuthAPI"

export const ManageMFAButton = ({type='button'}) => {

  const ManageMFA = async () => {
    try {
        const mfa_url = await AuthAPI.getManageMFA()
        window.location.href = mfa_url
    } catch (error) {
        console.error(error);
    }
  }

  return (
    <Fragment>
      {type === 'button' && <Button className="Header-changepassword font-light" type="button" onClick={() => ManageMFA()}>Update MFA</Button>}
      {type === 'menu' && <a className="menu-item font-light" href="#!" onClick={() => ManageMFA()}>Update MFA</a>}
    </Fragment>
  );
};
