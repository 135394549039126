import api from "./configs/AxiosConfig.js"
import { uploaded } from '../data/uploaded';
import Cookies from 'js-cookie'

const env = process.env.APP_ENV || process.env.NODE_ENV
const url = window.location.origin.toString();

const ListAPI = url + "/v2/file/list"
const DownloadAPI = url + "/v2/file/download"
const UploadAPI = url + "/v2/file/upload"
const DeleteAPI = url + "/v2/file/delete"
const DeleteAllAPI = url + "/v2/file/deleteall"

export const FileAPI = {
    list: async function (registration_id) {
        if (env === 'production') {
            const token = Cookies.get('token')
            const jsonToken = JSON.parse(token)
            const idToken = jsonToken.id_token
            const response = await api.get(ListAPI, {
                params: {
                    registration_id: registration_id,
                    date: Date.now()
                },
                headers: {
                    Authorization: `Bearer ${idToken}`
                }
            })
            return response.data
        } else {
            return uploaded;
        }
    },
    upload: async function (registration_id, file) {       
        if (env === 'production') {
            try {
                const token = Cookies.get('token')
                const jsonToken = JSON.parse(token)
                const idToken = jsonToken.id_token
                const urlResponse = await api.post(UploadAPI, null, {
                    params: {
                        registration_id: registration_id,
                        file_name: file.name,
                        file_type: file.type,
                        file_size: file.size
                    },
                    headers: {
                        Authorization: `Bearer ${idToken}`
                    }
                });
                const uploadURL = urlResponse.data
    
                await api.put(uploadURL, file, {
                    headers: {
                        'Content-Type': file.type
                    }
                })
            } catch (err) {
                console.log('upload error:', err)
            }
        } else {
            return file
        }
    },
    download: async function (id, fileName) {
        if (env === 'production') {
            const token = Cookies.get('token')
            const jsonToken = JSON.parse(token)
            const idToken = jsonToken.id_token
            const downloadURL = await api.get(DownloadAPI, {
                params: {
                    registration_id: id,
                    file_name: fileName
                },
                headers: {
                    Authorization: `Bearer ${idToken}`
                }
            });

            await api.get(downloadURL.data, { 
                responseType: 'blob',
            })
            .then((response) => {
                return response.data
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    fileName,
                );
            
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
        } else {
            return fileName
        }
    },
    delete: async function (id, fileName) {
        if (env === 'production') {
            const token = Cookies.get('token')
            const jsonToken = JSON.parse(token)
            const idToken = jsonToken.id_token
            const response = await api.delete(DeleteAPI, {
                params: {
                    registration_id: id,
                    file_name: fileName
                },
                headers: {
                    Authorization: `Bearer ${idToken}`
                }
            });
            return response.data;
        } else {
            return fileName
        }
    },
    deleteAll: async function (id) {
        if (env === 'production') {
            try {
                const token = Cookies.get('token')
                const jsonToken = JSON.parse(token)
                const idToken = jsonToken.id_token
                const response = await api.delete(DeleteAllAPI, {
                    params: {
                        registration_id: id,
                    },
                    headers: {
                        Authorization: `Bearer ${idToken}`
                    }
                });
                return response.data
            } catch (err) {
                console.log('deleteAll error:', err)
            }
            
        } else {
            return "";
        }
    }
}