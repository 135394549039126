import React, { useContext } from "react";
import { Button, IconButton, Tooltip } from '@mui/material';
import {
    Delete as DeleteIcon,
} from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DeleteRows } from "../utils/deleteUtils";
import ExamContext from "../context/ExamContext";
import NoteContext from '../context/NoteContext';
import ProgressContext from "../context/ProgressContext";

export const DeleteConfirmationDialog = ({rows}) => {
    const examContext = useContext(ExamContext)
    const noteContext = useContext(NoteContext)
    const progressContext = useContext(ProgressContext)
    const [open, setOpen] = React.useState(false);
    
    const handleClickOpen = (rows) => {
        if (rows && rows.length > 0) {
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };
    
    const DeleteProgress = (rows, examContext, noteContext, progressContext) => {
        if (rows && rows.length > 0) {
            progressContext.setProgressOpen(true)
            progressContext.setType('Delete')
            progressContext.setPercent(0)
            progressContext.setCurrentFileNumber(0)
            progressContext.setProgressRows(rows)
            progressContext.setFileTotal(rows.length)
        }

        DeleteRows(rows, examContext, noteContext, progressContext)
    }

    return (
        <React.Fragment>
            <Tooltip title="Delete">
                <IconButton onClick={() => handleClickOpen(rows)}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                className="DeletConfirmationDialog"
            >
                <DialogTitle 
                    id="responsive-dialog-title font-medium" 
                    className="Title"
                >
                Delete
                </DialogTitle>
                <DialogContent className="Content">
                    {rows.length === 1 && <DialogContentText className="Text font-medium">
                        Are you sure you want to delete this file?
                    </DialogContentText>}
                    {rows.length > 1 && <DialogContentText className="Text font-medium">
                        Are you sure you want to delete these {rows.length} files?
                    </DialogContentText>}
                </DialogContent>
                <DialogActions>
                <Button className="Cancel" autoFocus onClick={handleClose}>
                    Cancel
                </Button>
                <Button className="Confirm" onClick={() => DeleteProgress(rows, examContext, noteContext, progressContext)} autoFocus>
                    Confirm
                </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
};