import React, { useContext } from "react";
import { Button, IconButton, Tooltip } from '@mui/material';
import {
    Delete as DeleteIcon,
} from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AuthAPI } from "../apis/AuthAPI";
import ModalContext from "../context/ModalContext";

export const DeleteAccountDialog = ({type='button'}) => {
    const {
        deleteAccountOpen,
        setDeleteAccountOpen,
    } = useContext(ModalContext)
    
    const handleClickOpen = () => {
        setDeleteAccountOpen(true);
    };

    const handleClose = () => {
        setDeleteAccountOpen(false);
    };
    
    const DeleteAccount = async () => {
        try {
            const mfa_url = await AuthAPI.getDeleteAccount()
            window.location.href = mfa_url
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <React.Fragment>
            <React.Fragment>
                {type === 'button' && <Button className="Header-changepassword font-light" type="button" onClick={() => handleClickOpen()}>Delete Account</Button>}
                {type === 'menu' && <a className="menu-item font-light" href="#!" onClick={() => handleClickOpen()}>Delete Account</a>}
            </React.Fragment>
            <Dialog
                open={deleteAccountOpen}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                className="DeleteAccountDialog"
            >
                <DialogTitle 
                    id="responsive-dialog-title font-medium" 
                    className="Title"
                >
                Delete Account
                </DialogTitle>
                <DialogContent className="Content">
                    <DialogContentText className="Text font-medium">
                        <p>Are you sure you want to delete your account?</p>
                        <p>This will also delete all notes you have uploaded to CrestDrive.</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button className="Cancel" autoFocus onClick={handleClose}>
                    Cancel
                </Button>
                <Button className="Confirm" onClick={() => DeleteAccount()} autoFocus>
                    Confirm
                </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
};