import React, { useContext, useState } from "react";
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ExamContext from "../context/ExamContext";
import ModalContext from '../context/ModalContext';
import NoteContext from '../context/NoteContext';
import ProgressContext from "../context/ProgressContext";
import { Upload } from "../utils/uploadUtils";

export const UploadDuplicateDialog = ({disabled, selectedFiles, setUploadOpen}) => {
    const examContext = useContext(ExamContext)
    const noteContext = useContext(NoteContext)
    const progressContext = useContext(ProgressContext)
    const [open, setOpen] = useState(false);
    const [duplicateFiles, setDuplicateFiles] = useState([])
    const [currentFileIndex, setCurrentFileIndex] = useState(0);
    const [uploadFiles, setUploadFiles] = useState([])
    
    const handleClickOpen = (selectedFiles) => {

        if (selectedFiles && selectedFiles.length > 0) {
            const selectedFilesArray = Array.from(selectedFiles)
            setUploadFiles(selectedFilesArray)

            const currentNoteNames = examContext.notes && examContext.notes.length > 0 ? examContext.notes.map((note) => { return note.name }) : [];
            const selectedFilesNames = selectedFilesArray.map((file) => { return file.name})
            const duplicateFileNames = selectedFilesNames.filter(value => currentNoteNames.includes(value));
            
            if (duplicateFileNames && duplicateFileNames.length > 0) {
                setDuplicateFiles(duplicateFileNames)
                setOpen(true);
            } else {
                UploadProgress(selectedFilesArray)
            }
            
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getNewName = () => {
        let newName = duplicateFiles[currentFileIndex];
        let newNameExists = false
        while (!newNameExists) {
            const lastIndex = newName.lastIndexOf('.')
            const oldFileName = newName.substring(0,lastIndex);
            const extension = newName.substring(lastIndex, newName.length);
            let trimmedFileName = oldFileName;
            let duplicateNumber = 1
            if (oldFileName.match(/.*\ \(\d*\)/g)) {
                duplicateNumber = Number(oldFileName.replace(')', '').split(' (')[1]) + 1
                trimmedFileName = oldFileName.split(' (')[0]
            }
            newName = trimmedFileName + " (" + duplicateNumber + ")." + extension

            const currentNoteNames = examContext.notes && examContext.notes.length > 0 ? examContext.notes.map((note) => { return note.name }) : [];
            if (currentNoteNames.includes(newName) || duplicateFiles.includes(newName)) {
                console.log('name already in uploaded')
            } else {
                newNameExists = true
            }
        }
        return newName
    }

    const handleKeep = () => {
        const newName = getNewName()
        const newUploadFiles = Array.from(uploadFiles)

        const newFile = new File([newUploadFiles[currentFileIndex]], newName);
        newUploadFiles[currentFileIndex] = newFile

        setUploadFiles(newUploadFiles)

        if (currentFileIndex + 1 < duplicateFiles.length) {
            setCurrentFileIndex(currentFileIndex + 1)
        } else {
            if (newUploadFiles.length > 0) {
                UploadProgress(newUploadFiles)
            } else {
                setOpen(false)
            }
        }
    }

    const handleReplace = () => {
        if (currentFileIndex + 1 < duplicateFiles.length) {
            setCurrentFileIndex(currentFileIndex + 1)
        } else {
            if (uploadFiles.length > 0) {
                UploadProgress(uploadFiles)
            }
        }
    }
    
    const UploadProgress = (selectedFiles) => {
        if (selectedFiles && selectedFiles.length > 0) {
            progressContext.setProgressOpen(true)
            progressContext.setPercent(0)
            progressContext.setCurrentFileNumber(0)
            progressContext.setProgressRows(selectedFiles)
            progressContext.setFileTotal(selectedFiles.length)
            progressContext.setType('Upload')

            Upload(selectedFiles, examContext, noteContext, progressContext, handleClose, setUploadOpen)
        }
    }

    return (
        <React.Fragment>
            <button 
                className="Upload-button" onClick={() => handleClickOpen(selectedFiles)} disabled={disabled}
            >
                Upload
            </button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                className="DeletConfirmationDialog"
            >
                <DialogTitle 
                    id="responsive-dialog-title font-medium" 
                    className="Title"
                >
                Delete
                </DialogTitle>
                <DialogContent className="Content">
                    {duplicateFiles && <DialogContentText className="Text font-medium">
                        The file {duplicateFiles[currentFileIndex]} already exists, would you like to replace it?
                    </DialogContentText>}
                </DialogContent>
                <DialogActions>
                <Button className="Keep" autoFocus onClick={handleKeep}>
                    Keep
                </Button>
                <Button className="Replace" onClick={handleReplace} autoFocus>
                    Replace
                </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
};