// export const exams = [
//     {
//         "examSeriesName": "Exam 1",
//         "registrationId": "0000000",
//         "examDateTime": "2024-05-07T13:00:00Z",
//         "testCenterId": 4567
//     },
//     {
//         "examSeriesName": "Exam 2",
//         "registrationId": "0000001",
//         "examDateTime": "2024-05-12T10:00:00Z",
//         "testCenterId": 4567
//     },
//     {
//         "examSeriesName": "Exam 3",
//         "registrationId": "0000002",
//         "examDateTime": "2024-05-17T17:00:00Z",
//         "testCenterId": 4567
//     },
//     {
//         "examSeriesName": "Exam 4",
//         "registrationId": "0000003",
//         "examDateTime": "2024-05-24T08:00:00Z",
//         "testCenterId": 4567
//     },
//     {
//         "examSeriesName": "Exam 5",
//         "registrationId": "0000004",
//         "date": "2024-06-14T12:30:00Z",
//         "testCenterId": 4567
//     }
// ]

export const exams = [
    {
        "bookingId":"cf1b26f0-ea19-ef11-840a-6045bd1185e9",
        "registrationId": 5874094341,
        "examSeriesName": "CREST Registered Penetration Tester",
        "examSeriesCode": "CRT_PV",
        "examDateTime": "2024-08-21T19:20:48Z",
        "testCenterId": 4567
    },
    {
        "bookingId":"cf1b26f0-ea19-ef11-840a-6045bd1185e9",
        "registrationId": 5874094341,
        "examSeriesName": "CREST Registered Penetration Tester",
        "examSeriesCode": "CRT_PV",
        "examDateTime": "2024-08-21T19:20:48Z",
        // "examDateTime": "08/21/2024, 19:20:48",
        "testCenterId": 4567
    },
    {
        "bookingId":"cf1b26f0-ea19-ef11-840a-6045bd1185e9",
        "registrationId": 5874094341,
        "examSeriesName": "CREST Registered Penetration Tester",
        "examSeriesCode": "CRT_PV",
        "examDateTime": "2024-08-21T19:20:48Z",
        "testCenterId": 4567
    },
    {
        "bookingId":"cf1b26f0-ea19-ef11-840a-6045bd1185e9",
        "registrationId": 5874094341,
        "examSeriesName": "CREST Registered Penetration Tester",
        "examSeriesCode": "CRT_PV",
        "examDateTime": "2024-08-21T19:20:48Z",
        "testCenterId": 4567
    },
    {
        "bookingId":"cf1b26f0-ea19-ef11-840a-6045bd1185e9",
        "registrationId": 5874094341,
        "examSeriesName": "CREST Registered Penetration Tester",
        "examSeriesCode": "CRT_PV",
        "examDateTime": "2024-08-21T19:20:48Z",
        "testCenterId": 4567
    },
    {
        "bookingId":"cf1b26f0-ea19-ef11-840a-6045bd1185e9",
        "registrationId": 5874094341,
        "examSeriesName": "CREST Registered Penetration Tester",
        "examSeriesCode": "CRT_PV",
        "examDateTime": "2024-08-21T19:20:48Z",
        "testCenterId": 4567
    },
    {
        "bookingId":"cf1b26f0-ea19-ef11-840a-6045bd1185e9",
        "registrationId": 5874094341,
        "examSeriesName": "CREST Registered Penetration Tester",
        "examSeriesCode": "CRT_PV",
        "examDateTime": "2024-08-21T19:20:48Z",
        "testCenterId": 4567
    },
    {
        "bookingId":"cf1b26f0-ea19-ef11-840a-6045bd1185e9",
        "registrationId": 5874094341,
        "examSeriesName": "CREST Registered Penetration Tester",
        "examSeriesCode": "CRT_PV",
        "examDateTime": "2024-08-21T19:20:48Z",
        "testCenterId": 4567
    },
    {
        "bookingId":"cf1b26f0-ea19-ef11-840a-6045bd1185e9",
        "registrationId": 5874094341,
        "examSeriesName": "CREST Registered Penetration Tester",
        "examSeriesCode": "CRT_PV",
        "examDateTime": "2024-08-21T19:20:48Z",
        "testCenterId": 4567
    },
    {
        "bookingId":"cf1b26f0-ea19-ef11-840a-6045bd1185e9",
        "registrationId": 5874094341,
        "examSeriesName": "CREST Registered Penetration Tester",
        "examSeriesCode": "CRT_PV",
        "examDateTime": "2024-08-21T19:20:48Z",
        "testCenterId": 4567
    },
    {
        "bookingId":"cf1b26f0-ea19-ef11-840a-6045bd1185e9",
        "registrationId": 5874094341,
        "examSeriesName": "CREST Registered Penetration Tester",
        "examSeriesCode": "CRT_PV",
        "examDateTime": "2024-08-21T19:20:48Z",
        "testCenterId": 4567
    },
    {
        "bookingId":"cf1b26f0-ea19-ef11-840a-6045bd1185e9",
        "registrationId": 5874094341,
        "examSeriesName": "CREST Registered Penetration Tester",
        "examSeriesCode": "CRT_PV",
        "examDateTime": "2024-08-21T19:20:48Z",
        "testCenterId": 4567
    },
]