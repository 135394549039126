
import { formatMBToBytes } from "../utils/fileUtils";
import { FileAPI } from "../apis/FileAPI"
import { UpdateNotesList } from "../utils/noteUtils"

const timer = (delay) => {
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export const Upload = async (selectedFiles, examContext, noteContext, progressContext, handleClose, setUploadOpen) => {
    if (selectedFiles && selectedFiles.length > 0) {
        const totalSize = Object.values(selectedFiles).reduce((total, obj) => obj.size + total,0)
        if (totalSize < formatMBToBytes(examContext.remainingSpace)) {
            try {
                const percentPerFile = 100 / selectedFiles.length
                for (const [index, file] of Object.entries(selectedFiles)) {
                    const number = Number(index) + 1
                    progressContext.setCurrentFileNumber(number)
                    await FileAPI.upload(examContext.exam.registrationId, file)
                    await timer(200)
                    progressContext.setPercent(number * percentPerFile)
                }

                UpdateNotesList(examContext, noteContext)
                setUploadOpen(false)

            } catch(err) {
                console.log('Upload error', err)
            } 
        } else {
            console.log('Exceeds remaining space')
            progressContext.setProgressOpen(false)
            setUploadOpen(false)
            handleClose()
        }
        
    }
}