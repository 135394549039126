import React, {Fragment} from "react";
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Tooltip from '@mui/material/Tooltip';

export const HowToButton = ({type='button'}) => {
  return (
    <Fragment>
      {type === 'button' && <Tooltip title="How to use CRESTDrive" placement="bottom" followCursor >
        <Link to ='https://www.crest-approved.org/skills-certifications-careers/CRESTDrive' target="_blank" rel="noopener noreferrer">
          <Button className="Header-howto font-medium" type="button" data-testid="HowToButton">
              <QuestionMarkIcon />
          </Button>
        </Link>
      </Tooltip>}
      {type === 'menu' && <a className="menu-item font-light" href="https://www.crest-approved.org/skills-certifications-careers/CRESTDrive" data-testid="HowToMenu">How to use CRESTDrive</a>}
    </Fragment>
    
  );
};
