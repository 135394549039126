import React, { useEffect, useLayoutEffect, useState } from "react";
import { Routes, Route, useNavigate, useSearchParams } from 'react-router-dom';

import ExamPage from './pages/ExamPage';
import ViewPage from './pages/ViewPage';
import PageHeader from './components/PageHeader';
import PageFooter from './components/PageFooter';
import { AuthAPI } from "./apis/AuthAPI";
import { CookiesProvider, useCookies } from 'react-cookie';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { ThemeProvider } from '@mui/material';
import { MUITheme } from "./styles/MUITheme";
import { FileAPI } from "./apis/FileAPI";
import { D365API } from "./apis/D365API";
import Cookies from 'js-cookie'
import ExamsContext from './context/ExamsContext'
import NoteContext from './context/NoteContext'
import ModalContext from './context/ModalContext'
import UserContext from './context/UserContext'
import { NoCrestIdAlert } from "./components/Alerts/NoCrestIdAlert";
import * as dynamics from './lib/dynamics365';

import './styles/App.css';

function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [cookies, setCookie, removeCookie, updateCookies] = useCookies(['cookie']);
  const [cookieLoaded, setCookieLoaded] = useState(!!cookies.token)
  const [crestId, setCrestId] = useState();
  const [updateNotes, setUpdateNotes] = useState([]);
  const [termsOpen, setTermsOpen] = useState(false)
  const [deleteAccountOpen, setDeleteAccountOpen] = useState(false)
  const [progressOpen, setProgressOpen] = useState(false)
  const [exams, setExams] = useState([])
  const [examsLoaded, setExamsLoaded] = useState(false)
  const [familyName, setFamilyName] = useState()
  const [givenName, setGivenName] = useState()
  
  const navigate = useNavigate();

  const allowGetCode = (error, errorDescription, code, allowedErrors) => {
    if (!cookies.token && !error && !code) {
      return true
    } else if (!cookies.token && !code && error && errorDescription) {
      if (allowedErrors.some(errorID => errorDescription.includes(errorID))) {
        return true
      }
    } else {
      return false
    }
  }

  useLayoutEffect(() => {
    if (cookies.token && cookies.token.id_token_claims) {
      const claims = cookies.token.id_token_claims;
      if (claims.given_name && !givenName) {
        setGivenName(cookies.token.id_token_claims.given_name)
      }
      if (claims.family_name && !familyName) {
        setFamilyName(cookies.token.id_token_claims.family_name)
      }
      if (claims.extension_crestId && !crestId) {
        setCrestId(cookies.token.id_token_claims.extension_crestId)
      }
    }
  }, [cookies, cookieLoaded, crestId, givenName, familyName])

  useEffect(() => {
    const codeParam = searchParams.get('code')
    const errorParam = searchParams.get('error')

    if (cookies.token && crestId && (codeParam || errorParam)) {
      navigate('/', {
        replace: true
      })
    }

    const interval = setInterval(async () => {
      try {
        updateCookies()
        if (crestId && !Cookies.get('token')) {
          setCrestId(null)

          AuthAPI.getSignout().then((signout_url) => {
            window.location.href = signout_url
          })
        }
      } catch (error) {
          console.error(error);
      }
    }, 60000);

    const timeout = setTimeout(() => {
      if (updateNotes && updateNotes.length > 0) {
        for (const note of updateNotes) {
          D365API.updateNotes(note)
        }
      }
    }, 3540000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout)
    }
  })

  useEffect(() => {
    async function getToken(codeParam) {
      try {
        const acquiredToken = await AuthAPI.getToken(codeParam)
        if (acquiredToken) {          
          if (acquiredToken.error) {
            console.log('Token error', acquiredToken.error)
          } else {
            setCookie('token', JSON.stringify(acquiredToken), {
              secure: true,
              path: "/",
              maxAge: 3600
            })
            
            if (acquiredToken.id_token_claims) {
              const claims = acquiredToken.id_token_claims;
              if (claims.given_name) {
                setGivenName(claims.given_name)
              }
              if (claims.family_name) {
                setFamilyName(claims.family_name)
              }
              if (claims.extension_crestId) {
                setCrestId(claims.extension_crestId)
              }
            }
            window.location.reload();
          }
        }
      } catch (err) {
        console.log('error getting token...', err)
        navigate('/')
      }
    }
    
    const codeParam = searchParams.get('code')

    if (codeParam) {
      if (!cookies.token) {
        getToken(codeParam)
      }
    }
  }, [searchParams])

  useEffect(() => {
    const getExams = async () => {
      if (cookies.token && crestId && exams.length === 0) {
        try {
          const exams = await dynamics.getExams(crestId);
          setExams(exams)
          setExamsLoaded(true)
        } catch (err) {
          console.log('fetchRegistrations error', err)
          setExamsLoaded(true)
        }
      }
    }

    getExams()
  }, [exams, crestId])
  
  window.addEventListener("beforeunload", (event) => {
    try {
      if (updateNotes && updateNotes.length > 0) {
        for (const note of updateNotes) {
          D365API.updateNotes(note)
        }
      }
    } catch (error) {
        console.error(error);
    }
  });

  const UnauthenticatedContent = () => {
    const error = searchParams.get('error')
    const errorDescription = searchParams.get('error_description')
    const code = searchParams.get('code')
    const allowedErrors = ['AADB2C90091']
    
    if (allowGetCode(error, errorDescription, code, allowedErrors)) {
      AuthAPI.getCode().then((auth_url) => {
        window.location.href = auth_url
      })
    }

    if (cookies.token && !crestId) {
      if (cookies.token.id_token_claims && cookies.token.id_token_claims.given_name && !givenName) {
        setGivenName(cookies.token.id_token_claims.given_name)
      }
      if (cookies.token.id_token_claims && cookies.token.id_token_claims.family_name && !familyName) {
        setFamilyName(cookies.token.id_token_claims.family_name)
      }
      if (cookies.token.id_token_claims && cookies.token.id_token_claims.extension_crestId && !crestId) {
        setCrestId(cookies.token.id_token_claims.extension_crestId)
      } else {
        return (
          <NoCrestIdAlert />
        )
      }
    }

    if (!error || (errorDescription && allowedErrors.some(errorID => errorDescription.includes(errorID)))) {
      return (<CircularProgress className="Loading" data-testid="ExamsProgress" />)
    } else {
      return (<Alert severity="error" className="Alert">Error logging in: {error}.</Alert>)
    }
  }

  const deleteAccount = async () => {
    console.log('Deleting account')
    // send delete all notes request

    // for (const exam of exams) {
    //   const registrationId = exam.registrationId
    //   await FileAPI.deleteAll(registrationId)

    //   D365API.updateNotes({
    //     'registrationId': registrationId,
    //     'files': 0,
    //     'file_storage': '100MB of 100MB Remaining'
    //   });
    // }

    // removeCookie('token', {
    //   path: "/"
    // })

    // AuthAPI.getSignout().then((signout_url) => {
    //   window.location.href = signout_url
    // })
  }

  const AuthenticatedContent = ({cookies}) => {
    return (
      <UserContext.Provider
        value={{ crestId, setCrestId, familyName, setFamilyName, givenName, setGivenName }}
      >
        <ExamsContext.Provider
          value={{ exams, setExams, examsLoaded, setExamsLoaded }}
        >
            <Routes>
              <Route exact path="/" element={
                  <ExamPage cookies={cookies} setSearchParams={setSearchParams} />
              } />
              <Route exact path="/redirect" element={
                  <ExamPage cookies={cookies} setSearchParams={setSearchParams} />
              } />
              <Route exact path="/deleteAccount" element={deleteAccount} />
              <Route path=":id" element={
                  <ViewPage cookies={cookies} />
              } />
            </Routes>
        </ExamsContext.Provider>
      </UserContext.Provider>
    )
  }

  return (
    <ThemeProvider theme={MUITheme}>
      <CookiesProvider>
        <ModalContext.Provider
          value={{ termsOpen, setTermsOpen, progressOpen, setProgressOpen, deleteAccountOpen, setDeleteAccountOpen }}
        >
          <NoteContext.Provider
            value={{ updateNotes, setUpdateNotes }}
          >
            <div className="App">
              <PageHeader cookies={cookies} removeCookie={removeCookie} updateNotes={updateNotes}/>
              <div className="App-body">
                {cookies.token && crestId && <AuthenticatedContent cookies={cookies} setSearchParams={setSearchParams} />}
                {(!cookies.token || !crestId) && <UnauthenticatedContent />}
              </div>
              <PageFooter />
            </div>
          </NoteContext.Provider>
        </ModalContext.Provider>
      </CookiesProvider>
    </ThemeProvider>
  );
}

export default App;
