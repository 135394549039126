import React from "react";
import ContactUsModal from "./ContactUsModal";
import TermsModal from "./TermsModal";

import '../styles/App.css';

export default function PageFooter() {
    return (
        <div className="Footer">
            <ContactUsModal />
            <TermsModal />
        </div>
    );
}
