import { createTheme } from '@mui/material/styles';

export const MUITheme = () => {
    return createTheme({
        palette: {
            primary: {
                main: '#2EAEE4'
            },
            blue: {
                main: '#2EAEE4'
            },
            green: {
                main: '#25C7B7'
            },
            'dark-blue': {
                main: '#111D5E'
            },
            black: {
                main: '#0C1545'
            },
            'highlight-orange': {
                main: '#F58220'
            },
            'highlight-green': {
                main: '#9ACC6A'
            },
            'highlight-yellow': {
                main: '#FFC82F'
            },
            'grey-1': {
                main: '#396678'
            },
            'grey-2': {
                main: '#5D8395'
            },
            'grey-3': {
                main: '#85A4B3'
            },
            'grey-4': {
                main: '#B2C7D3'
            },
            'grey-5': {
                main: '#D1DFDD'
            },
        },
    })
}